@keyframes ellipsis {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.ellipsis-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dot {
    animation-name: ellipsis;
    animation-duration: 1.0s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    color: white;
}

    .dot:nth-child(2) {
        animation-delay: 0.2s;
    }

    .dot:nth-child(3) {
        animation-delay: 0.4s;
    }

